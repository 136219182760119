"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var defaultAppData = {
  'settings': {
    'basic_settings': {
      'title': {
        'lang_1': ''
      },
      'description': {
        'lang_1': ''
      },
      'languages': ['lang_1']
    },
    'context': {
      'labels': {
        'languages': {
          'lang_1': ''
        }
      },
      'menus': {
        'soldiagramMenu': {
          'lang_1': ''
        }
      },
      'routes': {
        'soldiagramRoute': ''
      },
      'titles': {
        'soldiagramTitle': ''
      }
    }
  },
  'sequence': {
    'firstFrame': "https://rematech.carco.hu/demo/soldiagram-app/1.webp",
    'lastFrame': "https://rematech.carco.hu/demo/soldiagram-app/47.webp",
    'duration': 500,
    'startTime': "0:00",
    'endTime': "24:00",
    'stepMinutes': 30,
    'stepTextMinutes': 240
  },
  'compass': {
    'degree': 240,
    'enable': true
  },
  'date': {
    'season': {
      'lang_1': 'Sommer'
    },
    'month': {
      'lang_1': 'Jun'
    },
    'day': '12'
  },
  'sunrise': '04:30',
  'sunset': '21:30'
};
var _default = defaultAppData;
exports["default"] = _default;